import React from "react"
import Logo from "../components/Logo"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

export default function Home() {
  return (
    <Layout>
      <Seo />
      <Logo />
    </Layout>
  )
}
